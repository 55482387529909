import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/compat/firestore';
import { combineLatest, Observable } from 'rxjs';
import { RoleInterface } from '../interfaces/role.interface';
import { map, shareReplay, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { RolePermissions } from 'src/app/shared/types/types';
import { collection, collectionData, getFirestore } from '@angular/fire/firestore';

const ROLES_COLLECTION = 'roles';

@Injectable()
export class RoleService {
  readonly userRolesCombinedPermissions$: Observable<RolePermissions>;
  private rolesCollection: AngularFirestoreCollection<RoleInterface>;
  private currentUserRoles: RoleInterface[];
  private allRoles: Observable<RoleInterface[]>;

  constructor(
    private readonly firestore: AngularFirestore,
    private readonly authService: AuthService
  ) {
    this.rolesCollection = this.firestore.collection<RoleInterface>(ROLES_COLLECTION);

    const firestoreDb = getFirestore();
    const roles$ = collectionData(collection(firestoreDb, ROLES_COLLECTION)) as Observable<RoleInterface[]>;

    this.userRolesCombinedPermissions$ = combineLatest({
      user: this.authService.user$,
      roles: roles$,
    }).pipe(
      map(({ user, roles }) => roles?.filter((role) => user?.roles.includes(role.name))),
      tap((roles) => (this.currentUserRoles = roles)),
      map((roles) => this.getPermissions(roles))
    );

    this.userRolesCombinedPermissions$.subscribe();
  }

  getAll(): Observable<RoleInterface[]> {
    if (!this.allRoles) {
      this.allRoles = this.rolesCollection.snapshotChanges().pipe(
        map((actions: DocumentChangeAction<RoleInterface>[]): RoleInterface[] => {
          return actions.map((action: DocumentChangeAction<RoleInterface>): RoleInterface => {
            const role = action.payload.doc.data();
            if (role) role.id = action.payload.doc.id;
            return role;
          });
        }),
        shareReplay(1)
      );
    }
    return this.allRoles;
  }

  getRoleById(roleId: string) {
    return this.rolesCollection.doc<RoleInterface>(roleId).valueChanges();
  }

  updateRole(roleId: string, role: RoleInterface) {
    return this.rolesCollection.doc<RoleInterface>(roleId).update(role);
  }

  createRole(role: RoleInterface) {
    const roleId = this.firestore.createId();
    return this.rolesCollection.doc<RoleInterface>(roleId).set(role);
  }

  deleteRole(roleId: string) {
    return this.rolesCollection.doc(roleId).delete();
  }

  getPermissions(roles = this.currentUserRoles): RolePermissions {
    return roles
      ?.map(({ permissions }) => permissions)
      .reduce((acc, current) => {
        for (const key in current) {
          if (current[key]) acc[key] = true;
        }
        return acc;
      }, {});
  }

  hasPermission(permission: string): boolean {
    return this.currentUserRoles?.some((role) => role?.permissions?.[permission]);
  }

  getActivePermissionsKeys(): string[] {
    return Object.entries(this.getPermissions())
      .filter(([, value]) => !!value)
      .map(([key]) => key);
  }
}
