import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { RouteService } from './core/services/route.service';
import { IdleWatcherService } from './core/services/idle-watcher.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly destroy = new Subject<void>();

  constructor(
    private readonly router: Router,
    private readonly routeService: RouteService,
    private readonly idleWatcherService: IdleWatcherService
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        tap((event: Event): void => this.routeService.changeRoute(event)),
        filter((event: Event): boolean => event instanceof NavigationEnd),
        takeUntil(this.destroy)
      )
      .subscribe();

    this.idleWatcherService.idle$.pipe(takeUntil(this.destroy)).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
