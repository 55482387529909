import { Routes } from '@angular/router';
import { ForgotPasswordComponent } from './core/forgot-password/forgot-password.component';
import { LoginComponent } from './core/login/login.component';
import { FirebaseAuthComponent } from './core/firebase-auth/firebase-auth.component';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'forgot',
    component: ForgotPasswordComponent,
  },
  {
    path: 'auth',
    component: FirebaseAuthComponent,
  },
  {
    path: '',
    loadChildren: () => import('./components/app-layout/app-layout.module').then(m => m.AppLayoutModule),
  },
];
