import { UserInterface } from '../interfaces/user.interface';
import firebase from 'firebase/compat/app';
import { CONSTANTS } from 'src/app/shared/utils/constants';
import { deepConvertDates } from '../../shared/utils/utils';

export class User implements UserInterface {
    public uid: string;
    public active: boolean;
    public medias: string[];
    public email: string;
    public photoURL: string;
    public displayName: string;
    public emailVerified: boolean;
    public roles: string[];
    public lastSignInTime: firebase.firestore.Timestamp; // TODO usar Date
    public lastLogSeen: string;
    public lastLogSeenByMedia: Record<string, Date>;
    public access?: string;
    public teams?: string[];
    public retailMedias?: {id: string, name: string}[];

    constructor(user?: UserInterface) {
        this.uid = (user?.uid) || '';
        this.active = (user?.active) || false;
        this.medias = (user?.medias) || [];
        this.email = (user?.email) || '';
        this.photoURL = (user?.photoURL) || 'https://goo.gl/Fz9nrQ';
        this.displayName = (user?.displayName) || '';
        this.emailVerified = (user?.emailVerified) || false;
        this.roles = (user?.roles) || [];
        this.lastSignInTime = (user?.lastSignInTime) || firebase.firestore.Timestamp.now(); // TODO usar Date
        this.lastLogSeen = (user?.lastLogSeen) || '';
        this.lastLogSeenByMedia = (user?.lastLogSeenByMedia) ? deepConvertDates(user.lastLogSeenByMedia) : {};
        this.access = (user?.access) || CONSTANTS.USER_ACCESS_TYPE_MEDIAS;
        this.teams = (user?.teams) || [];
        this.retailMedias = (user?.retailMedias) || [];
    }

    static fromFirebase(user: UserInterface): User {
      if(!user) return null;

      return new User(user);
    }

    static mapFromFirebase(users: UserInterface[]): User[] {
      return users.map(User.fromFirebase);
    }
}
