import { Injectable } from '@angular/core';
import { MediaInterface } from '../interfaces/media.interface';
import { SiteInterface } from '../interfaces/site.interface';
import { AuthService } from './auth.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { CONSTANTS } from 'src/app/shared/utils/constants';
import { Subject, ReplaySubject, Observable, combineLatest, from } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { FirebaseFCM } from '../interfaces/firebaseFCM.interface';
import { AppleCredentialsInterface } from '../interfaces/apple-credentials.interface';
import { ConfigurationsAppsInterface } from '../interfaces/configurations-apps.interface';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MediaService {
  private currentSiteSubject: Subject<string> = new ReplaySubject<string>(1); // TODO consider change it to BehaviorSubject.
  private currentSiteTypeSubject: Subject<string> = new ReplaySubject<string>(1); // TODO consider change it to BehaviorSubject.
  private currentGroupSubject: Subject<string> = new ReplaySubject<string>(1); // TODO consider change it to BehaviorSubject.
  private currentGroupTypeSubject: Subject<string> = new ReplaySubject<string>(1); // TODO consider change it to BehaviorSubject.
  private currentGroup$: Observable<string>;
  private currentGroupType$: Observable<string>;
  private currentSite$: Observable<string>;
  private currentSiteType$: Observable<string>;
  public allMedias: Observable<MediaInterface[]>;
  private readonly env = environment.env;

  public constructor(
    private readonly authService: AuthService,
    private fireDataBase: AngularFireDatabase,
    private readonly httpClient: HttpClient
  ) {
    this.currentSite$ = this.currentSiteSubject.asObservable();
    this.changeSite(localStorage.getItem('currentSite'));
    this.currentSiteType$ = this.currentSiteTypeSubject.asObservable();
    this.currentSiteTypeSubject.next(localStorage.getItem('currentSiteType') || 'web');

    this.currentGroup$ = this.currentGroupSubject.asObservable();
    this.changeGroup(localStorage.getItem('currentGroup'));
    this.currentGroupType$ = this.currentGroupTypeSubject.asObservable();
    this.currentGroupTypeSubject.next(localStorage.getItem('currentGroupType') || 'web');

  }

  public getCurrentSite(): Observable<string> {
    return this.currentSite$;
  }

  public getCurrentSiteType(): Observable<string> {
    return this.currentSiteType$;
  }

  public getCurrentGroup(): Observable<string> {
    return this.currentGroup$;
  }

  public changeSite(siteId: string): void {
    localStorage.setItem('currentSite', siteId);
    this.currentSiteSubject.next(siteId);
  }

  public changeGroup(mediaId: string): void {
    localStorage.setItem('currentGroup', mediaId);
    this.currentGroupSubject.next(mediaId);
  }

  public changeSiteType(siteType: string): void {
    localStorage.setItem('currentSiteType', siteType);
    this.currentSiteTypeSubject.next(siteType);
  }

  public getSiteFromStorage() {
    return localStorage.getItem('currentSite');
  }

  public clearStorage() {
    return localStorage.clear();
  }

  public getAll(): Observable<MediaInterface[]> {
    if (!this.allMedias) {
      this.allMedias = this.getMedias();
    }
    return this.allMedias;
  }

  public getAllSites(): Observable<SiteInterface[]> {
    return this.fireDataBase.list<SiteInterface>('/sites').valueChanges();
  }

  public getAllAllowed(): Observable<MediaInterface[]> {
    return combineLatest({
      medias: this.getAll(),
      user: this.authService.user$
    }).pipe(
      map(({ medias, user }) => {
        if (user) {
          return medias
            .filter((media: MediaInterface): boolean =>
              media.sites.some((site: SiteInterface): boolean =>
                user.medias.some((siteId: string): boolean => siteId === site.id)
              )
            )
            .map(
              (media: MediaInterface): MediaInterface => {
                media.sites = media.sites.filter((site: SiteInterface): boolean =>
                  user.medias.some((siteId: string): boolean => siteId === site.id)
                );
                return media;
              }
            );
        }
      })
    );
  }

  public getMedias(): Observable<MediaInterface[]> {
    return this.fireDataBase
      .list<MediaInterface>('/medias')
      .valueChanges()
      .pipe(
        map(changes => {
          return changes.map(media => {
            if (!media.sites) media.sites = [];
            return media;
          });
        }),
        shareReplay(1)
      );
  }
  public getAllActiveMedias(): Observable<MediaInterface[]> {
    return this.getMedias().pipe(
      map(medias => {
        const user = this.authService.userDetails;
        if (user) {
          return medias
            .filter((media: MediaInterface): boolean =>
              media.sites.some((site: SiteInterface): boolean =>
                user.medias.some((siteId: string): boolean => siteId === site.id)
              )
            )
            .map(
              (media: MediaInterface): MediaInterface => {
                media.sites = media.sites.filter((site: SiteInterface): boolean =>
                  user.medias.some((siteId: string): boolean => siteId === site.id)
                );
                return media;
              }
            );
        }
      })
    );
  }

  public getSiteNameById(siteId: string): Observable<string> {
    return this.getSiteById(siteId).pipe(map(site => site.name));
  }

  public getSiteById(siteId: string): Observable<SiteInterface> {
    return this.fireDataBase.object<SiteInterface>(`/sites/${siteId}`).valueChanges();
  }

  public getCurrentSiteInfo(): Observable<SiteInterface> {
    return this.getCurrentSite().pipe(switchMap(sideId => this.getSiteById(sideId)));
  }

  public getCurrentGroupInfo(): Observable<MediaInterface> {
    return this.getCurrentGroup().pipe(switchMap(mediaId => this.get(mediaId)));
  }

  public deleteSite(mediaId: string, siteId: string, media: any) {
    media.sites = media.sites.map(site => ({
      active: site.active,
      id: site.id,
      name: site.name,
      nameDetail: site.nameDetail,
    }));
    return Promise.all([
      this.updateMedia(mediaId, media),
      this.fireDataBase.object<SiteInterface>(`/sites/${siteId}`).remove(),
    ]);
  }

  public get(mediaId: string): Observable<MediaInterface> {
    return this.fireDataBase.object<MediaInterface>(`/medias/${mediaId}`).valueChanges();
  }

  public addWithCustomId(mediaId: string, media: MediaInterface) {
    return this.fireDataBase.object<MediaInterface>(`/medias/${mediaId}`).set(media);
  }

  public delete(mediaId: string): Promise<void> {
    return this.fireDataBase.object<MediaInterface>(`/medias/${mediaId}`).remove();
  }

  public updateMedia(mediaId: string, dataToUpdate: MediaInterface): Promise<void> {
    return this.fireDataBase.object<MediaInterface>(`/medias/${mediaId}`).update(dataToUpdate);
  }

  updateSiteHeaderData(
    mediaId: string,
    siteId: string,
    sitePayload: SiteInterface,
    mediaPayload: SiteInterface[]
  ): Observable<[unknown, unknown]> {
    return combineLatest([
      from(
        this.fireDataBase.database
          .ref()
          .child(`/sites/${siteId}`)
          .update(sitePayload)
      ),
      from(
        this.fireDataBase.database
          .ref()
          .child(`/medias/${mediaId}/sites`)
          .update(mediaPayload)
      ),
    ]);
  }

  updateSitePushNotificationDefaultData(siteId: string, payload: FirebaseFCM): Observable<void> {
    return from(
      this.fireDataBase.database
        .ref()
        .child(`/sites/${siteId}/certificateFCM`)
        .update(payload)
    );
  }

  updateSitePushNotificationSafariData(siteId: string, payload: AppleCredentialsInterface): Observable<void> {
    return from(
      this.fireDataBase.database
        .ref()
        .child(`/sites/${siteId}/appleCredentials`)
        .update(payload)
    );
  }

  updateSiteNewslettersData(siteId: string, payload: any): Observable<void> {
    return from(
      this.fireDataBase.database
        .ref()
        .child(`/sites/${siteId}/newslettersCredentials`)
        .update(payload)
    );
  }

  updateSitePrometeoData(siteId: string, payload: any): Observable<void> {
    return from(
      this.fireDataBase.database
        .ref()
        .child(`/sites/${siteId}/prometeoCredentials`)
        .update(payload)
    );
  }

  public getMediaParentBySiteId(siteId) {
    return this.getAll().pipe(
      map(medias => {
        return medias.find(media => media.sites.some(site => site.id === siteId));
      })
    );
  }

  updateAppData(siteId: string, credentials: ConfigurationsAppsInterface): Observable<void> {
    return from(
      this.fireDataBase.database
        .ref()
        .child(`/sites/${siteId}/configurationApps`)
        .update(credentials)
    );
  }

  updateSitesJavascriptWall(siteId: string, form: any): Observable<void> {
    return from(
      this.fireDataBase.database
        .ref()
        .child(this.javascriptWallPath(siteId))
        .update(form)
    );
  }

  getSitesJavascriptWall(siteId: string): Observable<any> {
    return this.fireDataBase.object<any>(this.javascriptWallPath(siteId)).valueChanges();
  }

  getMediasJavascriptWall(mediaId: string): Observable<any> {
    return this.fireDataBase.object<any>(`/medias/${mediaId}/javascriptWall`).valueChanges();
  }

  updateSitesTypeWall(siteId: any, javascript: any): Observable<any> {
    const body = {
      siteId: siteId,
      javascript: javascript,
    };
    return this.httpClient.post<any>(CONSTANTS.API_REST_UPDATE_SITES_TYPE_WALL, body);
  }

  updateSitesScriptFeatures(siteId: string, features: string[]): Observable<any> {
    const url = `${CONSTANTS.API_REST_UPDATE_SITES_SCRIPT_FEATURES}`.replace(':mediaId', siteId);
    const body = {
      features: features,
    };
    return this.httpClient.post<any>(url, body);
  }

  updateMediasTypeWall(siteIds: string[], javascript: string): Observable<string> {
    const body = {
      siteIds: siteIds,
      javascript: javascript,
    };
    return this.httpClient.post<any>(CONSTANTS.API_REST_UPDATE_MEDIAS_TYPE_WALL, body);
  }

  private javascriptWallPath(siteId: string): string {
    return `/sites/${siteId}/javascriptWall`;
  }

  getListOfSite() {
    return this.getAllSites().pipe(map((site: Array<any>) => site.map(value => ({ id: value.id, name: value.name }))));
  }

  getIdAndNameOfSites(): Observable<Array<{ id: string; name: string }>> {
    return this.getAllSites().pipe(
      map((site: Array<SiteInterface>) => site.map(value => ({ id: value.id, name: value.name })))
    );
  }

  updateGigyaData(siteId: string, form: any): Observable<void> {
    return from(
        this.fireDataBase.database
            .ref()
            .child(`/sites/${siteId}/gigyaConfig`)
            .update(form)
    );
  }
}
