import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { Utils } from '../../utils/utils';
import { toRoundedToEndOfDay } from '../../utils/date-utils';

type Value = string | number | boolean | undefined | Date | object;
type FilterType = 'simple' | 'select' | 'selectGroups' | 'dateRange';

interface BaseField {
  label: string;
  type: FilterType;
  name: string;
  placeholder?: string;
}

interface SimpleField extends BaseField {
  type: 'simple';
  value?: Value;
}

interface SelectField extends BaseField {
  type: 'select';
  multiple: boolean;
  options: {
    label: string;
    value: Value;
  }[];
  value?: Value;
}

interface SelectGroupsField extends BaseField {
  type: 'selectGroups';
  multiple: boolean;
  groups: {
    label: string;
    options: {
      label: string;
      value: Value;
    }[];
  }[];
  value?: Value;
}

interface DateRangeField extends BaseField {
  type: 'dateRange';
  controls: {
    start: string;
    end: string;
  };
  values?: {
    start?: Date;
    end?: Date;
  };
}

type FilterConfigField = SimpleField | SelectField | SelectGroupsField | DateRangeField;

export interface FilterConfig {
  fields: FilterConfigField[];
}

export interface FilterConfig {
  fields: FilterConfigField[];
}

@Component({
  selector: 'app-generic-filter',
  templateUrl: './filter.component.html',
})
export class FilterComponent<FilterValue> implements OnInit, OnDestroy {
  @Input() config: FilterConfig;
  @Output() filter = new EventEmitter<FilterValue>();

  form: UntypedFormGroup;
  private initial: FilterValue;
  private readonly destroy = new Subject<void>();

  ngOnInit(): void {
    this.form = this.createForm(this.config);
    this.initial = this.form.getRawValue();
    this.form.valueChanges.pipe(startWith(this.form.getRawValue()), takeUntil(this.destroy)).subscribe((value) => {
      this.config.fields
        .filter((field) => field.type === 'dateRange')
        .forEach((field) => {
          const {
            name,
            controls: { start, end },
          } = field as DateRangeField;
          const initialStart: Date = value[name][start];
          const initialEnd: Date = value[name][end];

          if (initialStart) {
            value[name][start] = Utils.alterTimeZone(initialStart);
          }

          if (initialEnd) {
            value[name][end] = toRoundedToEndOfDay(Utils.alterTimeZone(initialEnd));
          }
        });
      this.filter.emit(value);
    });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  reset(): void {
    this.form.reset(this.initial);
  }

  private createForm(filterConfig: FilterConfig): UntypedFormGroup {
    const formControls = filterConfig?.fields.reduce((controls, field) => {
      if (field.type === 'dateRange') {
        controls[field.name] = new UntypedFormGroup({
          start: new UntypedFormControl(field.values?.start),
          end: new UntypedFormControl(field.values?.end),
        });
      } else if (field.type === 'select' || field.type === 'selectGroups') {
        controls[field.name] = new UntypedFormControl(field.multiple ? [] : field.value);
      } else {
        controls[field.name] = new UntypedFormControl(field.value);
      }

      return controls;
    }, {});

    return new UntypedFormGroup(formControls);
  }
}
