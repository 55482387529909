import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActionInterface } from '../../../core/interfaces/action.interface';
import { TableColumn } from './column.interface';
import ConstantMessages, { NOTIFY_MESSAGE } from '../../utils/constants-messages/constants-messages';
import { SNACK_BAR } from '../../utils/constants';

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss'],
})
export class GenericTableComponent<T> implements OnInit, OnChanges {
  @Input() thereIsData: boolean;
  @Input() dataSource: MatTableDataSource<T>;
  @Input() columns: TableColumn<T>[];
  @Input() actions: ActionInterface[];
  @Input() actionsPerRow: boolean; // use this when actions are different for each row
  @Input() tableStyle: string;
  @Output() actionChange = new EventEmitter<{ element: T; event: MatSelectChange }>();
  @Output() pageChange = new EventEmitter<{ event: PageEvent }>();
  @Output() outputPageChange = new EventEmitter<{ event: PageEvent }>();
  @Output() pageManagedByBackendChange = new EventEmitter<string>();
  @Input() paginatorLength: number;
  @Input() pageSize = 10;
  @Input() pageIndex: number;
  @Input() pageSizeOptions: number[] = [10, 25, 50, 100];
  @Input() paginationManagedByBackend: boolean;
  @Input() paginationByBackendLength: number;
  @Output() buttonClick = new EventEmitter<{ element: T }>();

  columnsNames: string[];
  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  pageEvent: PageEvent;

  @ViewChild(MatSort) public set matSort(sort: MatSort) {
    this.sort = sort;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) public set matPaginator(paginator: MatPaginator) {
    this.paginator = paginator;
    this.setDataSourceAttributes();
  }
  private sort: MatSort;
  private paginator: MatPaginator;

  constructor(private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.loadColumns();
  }

  loadColumns = () => {
    this.columnsNames = this.columns.map(e => e.columnDef);
    if ((this.actions && this.actions.length > 0) || this.actionsPerRow) {
      this.columnsNames.push('actions');
    }
  };

  handlePageEvent(event: PageEvent) {
    this.outputPageChange.emit({ event });
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadColumns();
    if (!!this.paginator && !!changes.pageIndex && changes.pageIndex.currentValue === 0) this.paginator.pageIndex = 0;
  }

  /**
 * @deprecated Use ClipBoardService instead
 */
  public copyToClipboard(text: string): void {
    // TODO ⚽ --> sustituir función cuando se actualice angular materials --> https://material.angular.io/cdk/clipboard/overview
    const selectBox = document.createElement('textarea');
    selectBox.style.position = SNACK_BAR.STYLE.POSITION;
    selectBox.style.left = SNACK_BAR.STYLE.LEFT;
    selectBox.style.top = SNACK_BAR.STYLE.TOP;
    selectBox.style.opacity = SNACK_BAR.STYLE.OPACITY;
    selectBox.value = text;
    document.body.appendChild(selectBox);
    selectBox.focus();
    selectBox.select();
    document.execCommand('copy');
    document.body.removeChild(selectBox);
    this.snackBar.open(NOTIFY_MESSAGE.CLIPBOARD.SUCCESS, ConstantMessages.AcceptText, SNACK_BAR.CONFIGURATION);
  }

  public setDataSourceAttributes(): void {
    if (!!this.dataSource) {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  public onButtonClick(element: T): void {
    this.buttonClick.emit({ element });
  }

  onActionChange(element: T, event: MatSelectChange): void {
    this.actionChange.emit({ element, event });
  }

  onPageChange(event: PageEvent): void {
    this.pageChange.emit({ event });
  }

  public getLength = () => this.paginatorLength || (!!this.dataSource && this.dataSource.data.length);
}
