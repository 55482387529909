import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, fromEvent, merge, Observable, switchMap, timer } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { hours, seconds } from '../../shared/constants';
import { AlertService, Swal } from '../../shared/services/alert.service';
import { NOTIFY_MESSAGE } from '../../shared/utils/constants-messages/constants-messages';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class IdleWatcherService {
  readonly idle$: Observable<unknown>;
  private readonly LIMIT = hours(1);

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly alertService: AlertService
  ) {
    const events = [
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'keydown'),
      fromEvent(document, 'click'),
      fromEvent(document, 'scroll'),
    ];

    this.idle$ = merge(...events).pipe(
      filter(() => this.router.url !== '/login'),
      tap(() => this.closeModal()),
      switchMap(() => this.startIdleTimer())
    );
  }

  private closeModal(): void {
    Swal.getTimerLeft() && Swal.close();
  }

  private startIdleTimer(): Observable<unknown> {
    return timer(this.LIMIT).pipe(switchMap(() => this.handleIdle()));
  }

  private handleIdle(): Observable<unknown> {
    const { IDLE } = NOTIFY_MESSAGE.CORE;
    return from(
      this.alertService.warning({
        title: IDLE.TITLE,
        message: IDLE.USER_IDLE,
        showConfirmButton: false,
        showCancelButton: false,
        timer: seconds(10),
      })
    ).pipe(
      switchMap(() => from(this.authService.logout())),
      switchMap(() =>
        from(
          this.alertService.info({
            title: IDLE.TITLE,
            message: IDLE.SESSION_CLOSED,
          })
        )
      )
    );
  }
}
